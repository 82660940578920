import PRODUCTS_CONSTANTS from './productsConstants';
import axios from 'axios';
import { showNotification } from '../../../components/helpers/notificationHelper';
import notificationTypes from '../../../components/helpers/notificationTypes';
import { toISODateTime } from '../../../components/helpers/dateFormatter';
import { getQueryString } from '../../../components/helpers/queryHelper';
import dateFormats from '../../../components/helpers/dateFormats';
import moment from 'moment';
import { getErrorsForNotificationPopup } from '../../../components/helpers/getErrorsForNotificationPopup';

export const importProducts = () => (dispatch) => {
  dispatch({
    type: PRODUCTS_CONSTANTS.PRODUCTS_PENDING,
    payload: true,
  });
  return axios.post('/products/import').then(() => {
    dispatch({
      type: PRODUCTS_CONSTANTS.PRODUCTS_PENDING,
      payload: false,
    });
    showNotification(notificationTypes.success, 'Продукты успешно импортированы', '');
  });
};

export const importImages = () => (dispatch) => {
  dispatch({
    type: PRODUCTS_CONSTANTS.PRODUCTS_PENDING,
    payload: true,
  });
  return axios.post('/products/importImages').then(() => {
    dispatch({
      type: PRODUCTS_CONSTANTS.PRODUCTS_PENDING,
      payload: false,
    });
    showNotification(notificationTypes.success, 'Картинки успешно импортированы', '');
  });
};

export const importProductsFromXml = (xmlFileData) => (dispatch) => {
  dispatch({
    type: PRODUCTS_CONSTANTS.PRODUCTS_PENDING,
    payload: true,
  });
  return axios
    .post('/products/importXml', xmlFileData)
    .then((response) => {
      dispatch({
        type: PRODUCTS_CONSTANTS.PRODUCTS_PENDING,
        payload: false,
      });

      showNotification(notificationTypes.success, 'Продукты успешно импортированы', '');

      return response.data;
    })
    .catch((error) => {
      dispatch({
        type: PRODUCTS_CONSTANTS.PRODUCTS_PENDING,
        payload: false,
      });

      let errorMessage = '';
      if (error.response && error.response.data) {
        errorMessage = getErrorsForNotificationPopup(error.response.data.errors);
      }

      showNotification(
        notificationTypes.error,
        errorMessage || 'Пожалуйста, проверьте корректность структуры файла',
        'Не удалось импортировать продукты',
      );
    });
};

export const getProducts =
  (
    pageNumber,
    productsPerPage,
    searchString,
    categoriesIds,
    sortOrder,
    selectedFridge,
    showProductsWithCustomPriceOnly,
  ) =>
  (dispatch) => {
    dispatch({
      type: PRODUCTS_CONSTANTS.PRODUCTS_PENDING,
      payload: true,
    });

    let categoryIdsQuery = `&categories=${[]}`;

    if (categoriesIds) {
      categoryIdsQuery = categoriesIds
        .map((v) => {
          return `&categories=${v.value || v}`;
        })
        .join('');
    }

    return axios
      .get(
        `/products?pageNumber=${pageNumber}&itemsPerPage=${productsPerPage}&searchString=${searchString}${categoryIdsQuery}` +
          `&sortOrder=${sortOrder}&fridgeId=${selectedFridge}&showProductsWithCustomPriceOnly=${showProductsWithCustomPriceOnly}`,
      )
      .then((response) =>
        dispatch({
          type: PRODUCTS_CONSTANTS.GET_PRODUCTS,
          payload: {
            products: response.data.items,
            productsCount: response.data.totalItems,
            currentPage: response.data.currentPage,
          },
        }),
      );
  };

export const getProduct = (productId, history) => (dispatch) => {
  dispatch({
    type: PRODUCTS_CONSTANTS.PRODUCTS_PENDING,
    payload: true,
  });
  return axios
    .get(`/products/${productId}`)
    .then((response) =>
      dispatch({
        type: PRODUCTS_CONSTANTS.GET_PRODUCT_BY_ID,
        payload: {
          product: response.data,
        },
      }),
    )
    .catch(() => {
      dispatch({
        type: PRODUCTS_CONSTANTS.PRODUCTS_PENDING,
        payload: false,
      });
      history.push('/products');
    });
};

export const getProductCategories = () => (dispatch) => {
  dispatch({
    type: PRODUCTS_CONSTANTS.PRODUCTS_PENDING,
    payload: true,
  });
  return axios.get(`/products/categories`).then((response) =>
    dispatch({
      type: PRODUCTS_CONSTANTS.GET_PRODUCT_CATEGORIES,
      payload: {
        productCategories: response.data,
      },
    }),
  );
};

export const addProduct = (product) => (dispatch) => {
  dispatch({
    type: PRODUCTS_CONSTANTS.PRODUCTS_PENDING,
    payload: true,
  });
  return axios
    .post('/products', {
      barcodeValue: product.barcodeValue,
      hasDataMatrix: product.hasDataMatrix,
      name: product.name,
      defaultPrice: Number(product.price),
      productionCost: product.productionCost ? Number(product.productionCost) : null,
      imageUrl: product.imageUrl,
      proteins: Number(product.proteins),
      fats: Number(product.fats),
      carbohydrates: Number(product.carbohydrates),
      kiloCalories: Number(product.kiloCalories),
      ingredientsText: product.ingredients,
      categoryId: product.category.value,
    })
    .then((response) => {
      dispatch({
        type: PRODUCTS_CONSTANTS.PRODUCTS_PENDING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: PRODUCTS_CONSTANTS.PRODUCTS_PENDING,
        payload: false,
      });
      let errorMessage = '';
      if (error.response && error.response.data) {
        errorMessage = getErrorsForNotificationPopup(error.response.data.errors);
      }

      throw errorMessage;
    });
};

export const updateProduct = (product, productId) => (dispatch) => {
  dispatch({
    type: PRODUCTS_CONSTANTS.PRODUCTS_PENDING,
    payload: true,
  });
  return axios
    .put(`/products`, {
      productId: productId,
      product: {
        barcodeValue: product.barcodeValue,
        hasDataMatrix: product.hasDataMatrix,
        name: product.name,
        imageUrl: product.imageUrl,
        defaultPrice: Number(product.price),
        productionCost: product.productionCost ? Number(product.productionCost) : null,
        proteins: Number(product.proteins),
        fats: Number(product.fats),
        carbohydrates: Number(product.carbohydrates),
        kiloCalories: Number(product.kiloCalories),
        ingredientsText: product.ingredients,
        notes: product.notes,
        categoryId: product.category.value,
      },
    })
    .then((response) => {
      dispatch({
        type: PRODUCTS_CONSTANTS.PRODUCTS_PENDING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: PRODUCTS_CONSTANTS.PRODUCTS_PENDING,
        payload: false,
      });
      let errorMessage = '';
      if (error.response && error.response.data) {
        errorMessage = getErrorsForNotificationPopup(error.response.data.errors);
      }

      throw errorMessage;
    });
};

export const getDetailedProductCategories = (pageNumber, itemsPerPage, searchString) => (dispatch) => {
  dispatch({
    type: PRODUCTS_CONSTANTS.PRODUCTS_PENDING,
    payload: true,
  });
  return axios
    .get(
      `/products/categories/detailed?pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}&searchString=${searchString}`,
    )
    .then((response) =>
      dispatch({
        type: PRODUCTS_CONSTANTS.GET_DETAILED_CATEGORIES,
        payload: {
          productCategories: response.data.items,
          categoriesCount: response.data.totalItems,
          currentPage: response.data.currentPage,
        },
      }),
    );
};

export const updateProductCategory = (category, categoryId) => (dispatch) => {
  dispatch({
    type: PRODUCTS_CONSTANTS.PRODUCTS_PENDING,
    payload: true,
  });
  return axios
    .put(`/products/categories`, {
      categoryId: categoryId,
      name: category.name,
    })
    .then((response) => {
      dispatch({
        type: PRODUCTS_CONSTANTS.PRODUCTS_PENDING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: PRODUCTS_CONSTANTS.PRODUCTS_PENDING,
        payload: false,
      });
      let errorMessage = '';
      if (error.response && error.response.data) {
        errorMessage = getErrorsForNotificationPopup(error.response.data.errors);
      }

      throw errorMessage;
    });
};

export const addProductCategory = (category) => (dispatch) => {
  dispatch({
    type: PRODUCTS_CONSTANTS.PRODUCTS_PENDING,
    payload: true,
  });
  return axios
    .post('/products/categories', {
      name: category.name,
    })
    .then((response) => {
      dispatch({
        type: PRODUCTS_CONSTANTS.PRODUCTS_PENDING,
        payload: false,
      });
    })
    .catch((error) => {
      dispatch({
        type: PRODUCTS_CONSTANTS.PRODUCTS_PENDING,
        payload: false,
      });
      let errorMessage = '';
      if (error.response && error.response.data) {
        errorMessage = getErrorsForNotificationPopup(error.response.data.errors);
      }

      throw errorMessage;
    });
};

export const getCategoryById = (categoryId, history) => (dispatch) => {
  dispatch({
    type: PRODUCTS_CONSTANTS.PRODUCTS_PENDING,
    payload: true,
  });
  return axios
    .get(`/products/categories/${categoryId}`)
    .then((response) =>
      dispatch({
        type: PRODUCTS_CONSTANTS.GET_CATEGORY_BY_ID,
        payload: {
          productCategory: response.data,
        },
      }),
    )
    .catch(() => {
      dispatch({
        type: PRODUCTS_CONSTANTS.PRODUCTS_PENDING,
        payload: false,
      });
      history.push('/products/categories');
    });
};

export const getProductsRating = (pageNumber, productsPerPage, startDate, endDate, fridgesIds) => (dispatch) => {
  dispatch({
    type: PRODUCTS_CONSTANTS.PRODUCTS_PENDING,
    payload: true,
  });

  startDate = moment(startDate, dateFormats.shortDateTime).startOf('day');
  endDate = moment(endDate, dateFormats.shortDateTime).endOf('day');

  const fridgesAndDateRangeQuery = getQueryString({
    startDate: toISODateTime(startDate),
    endDate: toISODateTime(endDate),
    fridges: fridgesIds,
  });

  return axios
    .get(
      `/products/rating?pageNumber=${pageNumber}&itemsPerPage=${productsPerPage}&${fridgesAndDateRangeQuery.substr(1)}`,
    )
    .then((response) =>
      dispatch({
        type: PRODUCTS_CONSTANTS.GET_PRODUCTS_RATING,
        payload: {
          products: response.data.items,
          productsCount: response.data.totalItems,
          currentpage: response.data.currentPage,
        },
      }),
    );
};

export const getPorductCustomPrices = (pageNumber, itemsPerPage, productId) => (dispatch) => {
  dispatch({
    type: PRODUCTS_CONSTANTS.PRODUCTS_PENDING,
    payload: true,
  });

  return axios
    .get(`/products/${productId}/fridgeprices?pageNumber=${pageNumber}&itemsPerPage=${itemsPerPage}`)
    .then((response) =>
      dispatch({
        type: PRODUCTS_CONSTANTS.GET_PRODUCT_CUSTOM_PRICES,
        payload: {
          fridgePrices: response.data.items,
          fridgePricesCount: response.data.totalItems,
          currentPage: response.data.currentPage,
        },
      }),
    );
};

export const getPorductCustomPriceInFridge = (productId, fridgeId) => (dispatch) => {
  dispatch({
    type: PRODUCTS_CONSTANTS.PRODUCTS_PENDING,
    payload: true,
  });

  return axios.get(`/products/${productId}/fridgeprices?fridgeId=${fridgeId}`).then((response) =>
    dispatch({
      type: PRODUCTS_CONSTANTS.GET_PRODUCT_CUSTOM_PRICE_IN_FRIDGE,
      payload: {
        price: response.data.price,
      },
    }),
  );
};

export const setProductCustomPrice = (productId, fridgeId, price) => (dispatch) => {
  dispatch({
    type: PRODUCTS_CONSTANTS.PRODUCTS_PENDING,
    payload: true,
  });

  return axios
    .post(`/products/${productId}/fridgeprices`, { fridgeId: fridgeId, price: price })
    .then((response) =>
      dispatch({
        type: PRODUCTS_CONSTANTS.PRODUCTS_PENDING,
        payload: false,
      }),
    )
    .catch((error) => {
      dispatch({
        type: PRODUCTS_CONSTANTS.PRODUCTS_PENDING,
        payload: false,
      });
      let errorMessage = '';
      if (error.response && error.response.data) {
        errorMessage = getErrorsForNotificationPopup(error.response.data.errors);
      }

      throw errorMessage;
    });
};

export const deleteProductCustomPrice = (productId, fridgeId) => (dispatch) => {
  dispatch({
    type: PRODUCTS_CONSTANTS.PRODUCTS_PENDING,
    payload: true,
  });

  return axios.delete(`/products/${productId}/fridgeprices?fridgeId=${fridgeId}`).then((response) =>
    dispatch({
      type: PRODUCTS_CONSTANTS.DELETE_PRODUCT_CUSTOM_PRICE_IN_FRIDGE,
      payload: {
        isLoading: false,
        fridgeId: fridgeId,
      },
    }),
  );
};
