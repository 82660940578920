import React from 'react';
import Button from '@material-ui/core/Button';
import InputField from '../../fields/Input/InputField';
import { withStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import MultilineInputField from '../../fields/Input/MutilineInputField';
import CategoriesSelectField, { customStyles } from '../../fields/Select/CategoriesSelectField';
import { ImageUploadField } from '../../fields/ImageUpload/ImageUploadField';
import { useTranslation } from 'react-i18next';
import CheckboxField from '../../fields/Checkbox/CheckboxField';

const styles = (theme) => ({
  button: {
    fontSize: '18px',
    padding: '4px 16px',
    width: '60%',
    backgroundColor: theme.palette.primary.dark,
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
    color: theme.palette.white,
  },
  title: {
    color: theme.palette.primary.dark,
  },
  childElement: {
    width: '100%',
    marginLeft: '50px',
    marginTop: '5px',
    marginBottom: '5px',
  },
  flexContainer: {
    minWidth: '650px',
    marginRight: 'auto',
    marginLeft: 'auto',
    marginTop: '30px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  input: {
    border: 'none',
    width: '600px',
    minWidth: '350px',
    fontSize: '16px',
    fontWeight: 400,
    resize: 'none',
    margin: '2% 5% 1% 5%',
    fontFamily: theme.typography.fontFamily,
  },
  formField: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '97%',
  },
  checkboxField: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '4px',
    backgroundColor: theme.palette.whitegrey,
    '&:hover': {
      backgroundColor: theme.palette.grey.light,
      borderColor: theme.palette.black,
      borderWidth: '1px',
    },
  },
  checkboxText: {
    fontSize: '1rem',
    color: theme.palette.grey.dark,
  },
  selectedCheckboxText: {
    fontSize: '14px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
});

const AddUpdateProductForm = (props) => {
  const { classes, pristine, categories, pageTitle, hasDataMatrix } = props;
  const { t } = useTranslation();
  return (
    <form onSubmit={props.handleSubmit} className={classes.flexContainer}>
      <div className={classes.childElement}>
        <Typography className={classes.title} color={'primary'} variant={'h5'}>
          {pageTitle}
        </Typography>
      </div>
      <InputField className={classes.input} name="barcodeValue" label={t('Product barcode')} variant="outlined" />
      <div className={`${classes.input} ${classes.checkboxField}`}>
        <CheckboxField
          label={
            <Typography className={`${classes.checkboxText} ${hasDataMatrix && classes.selectedCheckboxText}`}>
              {t('Has datamatrix')}
            </Typography>
          }
          name="hasDataMatrix"
          className={classes.formField}
        />
      </div>
      <InputField className={classes.input} name="name" label={t('Product name')} variant="outlined" />
      <InputField className={classes.input} name="price" label={t('Product price')} variant="outlined" />
      <InputField
        className={classes.input}
        name="productionCost"
        label={t('Product production cost')}
        variant="outlined"
      />
      <InputField
        className={classes.input}
        name="proteins"
        label={t('Amount of proteins in the product')}
        variant="outlined"
      />
      <InputField className={classes.input} name="fats" label={t('Amount of fats in the product')} variant="outlined" />
      <InputField
        className={classes.input}
        name="carbohydrates"
        label={t('Amount of carbohydrates in the product')}
        variant="outlined"
      />
      <InputField
        className={classes.input}
        name="kiloCalories"
        label={t('Product caloric content')}
        variant="outlined"
      />
      <MultilineInputField
        className={classes.input}
        name="ingredients"
        label={t('Product ingredients')}
        variant="outlined"
      />
      <MultilineInputField className={classes.input} name="notes" label={t('Product notes')} variant="outlined" />
      <CategoriesSelectField
        placeholder={t('Product category')}
        styles={customStyles}
        categories={categories}
        className={classes.input}
        name="category"
      />
      <div className={classes.childElement}>
        <Typography className={classes.title} color={'primary'} variant={'subtitle2'}>
          {t('Product image:')}
        </Typography>
      </div>
      <ImageUploadField name="imageUrl" variant="outlined" />
      <div className={classes.childElement}>
        <Button disabled={pristine} variant="contained" type="submit" className={classes.button}>
          {t('Save')}
        </Button>
      </div>
    </form>
  );
};

export default withStyles(styles)(AddUpdateProductForm);
